document.addEventListener("turbolinks:load", function () {
  $("#member_entitlement_one_quantity").on('change', function () {
    if ($(this).val() === '1_dinning_voucher') {
      $("#member_entitlement_one_no_dinners").val(4);
    }
    else if ($(this).val() === '2_dinning_vouchers') {
      $("#member_entitlement_one_no_dinners").val(8);
    }
  });
  $("#member_entitlement_three_quantity").on('change', function () {
    if ($(this).val() === '2_flight_tickets') {
      $("#member_entitlement_three_no_passengers").val(2);
    }
    else if ($(this).val() === '3_flight_tickets') {
      $("#member_entitlement_three_no_passengers").val(3);
    }
    else if ($(this).val() === '4_flight_tickets') {
      $("#member_entitlement_three_no_passengers").val(4);
    }
  });

  $("#member_entitlement_four_quantity").on('change', function () {
    if ($(this).val() === '1_holiday_package') {
      $("#member_entitlement_four_no_nights").val(6);
    }
    else if ($(this).val() === '3_holiday_packages') {
      $("#member_entitlement_four_no_nights").val(18);
    }
    else if ($(this).val() === '5_holiday_packages') {
      $("#member_entitlement_four_no_nights").val(30);
    }
    else if ($(this).val() === '10_holiday_packages') {
      $("#member_entitlement_four_no_nights").val(60);
    }
  });
});
