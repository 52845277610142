document.addEventListener("turbolinks:load", function () {
  $('#package_purchased_price_error').hide();

  // membership_actual_price = document.getElementById('member_package_published_price').value;
  // membership_price = document.getElementById('member_package_purchased_price').value;
  // total_discount = parseInt(membership_actual_price) - parseInt(membership_price);
  // check_minimum_membership_price(membership_price);

  // member_package_discount = document.getElementById('member_package_discount').value = total_discount;

  $('#member_package_purchased_price').on('keyup', function () {
    membership_actual_price = document.getElementById('member_package_published_price').value;
    membership_price = document.getElementById('member_package_purchased_price').value;
    total_discount = parseInt(membership_actual_price) - parseInt(membership_price);
    member_package_discount = document.getElementById('member_package_discount').value = total_discount;
    check_minimum_membership_price(membership_price);
  });

  function check_minimum_membership_price(membership_price) {
    minimum_membership_price = 5095;
    if (membership_price < minimum_membership_price) {
      $('#submit-member-form').hide();
      $('#package_purchased_price_error').show();
    }
    else {
      $('#package_purchased_price_error').hide();
      $('#submit-member-form').show();
    }
  }
});
