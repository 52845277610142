document.addEventListener("turbolinks:load", function () {
  $("#member_payment_method_one_amount").on('keyup', function () {
    check_final_total();
  });
  $("#member_payment_method_two_amount").on('keyup', function () {
    check_final_total();
  });
  $("#member_balance_no_of_installments").on('change', function () {
    member_balance_payment_amount = $("#member_balance_payment_amount").val();

    switch (parseInt($(this).val())) {
      case 1:
        $("#member_balance_payment_emi_amount").val(member_balance_payment_amount);
        break;
      case 3:
        calculate = Math.round((member_balance_payment_amount / 3));
        $("#member_balance_payment_emi_amount").val(calculate);
        break;
      case 6:
        calculate = Math.round((member_balance_payment_amount / 6));
        $("#member_balance_payment_emi_amount").val(calculate);
        break;
      case 9:
        calculate = Math.round((member_balance_payment_amount / 9));
        $("#member_balance_payment_emi_amount").val(calculate);
        break;
      case 12:
        calculate = Math.round((member_balance_payment_amount / 12));
        $("#member_balance_payment_emi_amount").val(calculate);
        break;
      case 18:
        calculate = Math.round((member_balance_payment_amount / 18));
        $("#member_balance_payment_emi_amount").val(calculate);
        break;
      case 24:
        calculate = Math.round((member_balance_payment_amount / 24));
        $("#member_balance_payment_emi_amount").val(calculate);
        break;
      case 36:
        calculate = Math.round((member_balance_payment_amount / 36));
        $("#member_balance_payment_emi_amount").val(calculate);
    }
  });

  function check_final_total() {
    member_package_purchased_price_value = $("#member_package_purchased_price").val();

    member_payment_method_one_amount_value = parseInt($("#member_payment_method_one_amount").val() || 0);
    member_payment_method_two_amount_value = parseInt($("#member_payment_method_two_amount").val() || 0);

    total = parseInt(member_payment_method_one_amount_value + member_payment_method_two_amount_value);
    $("#member_payment_method_total").val(total);
    $("#member_payment_method_total_in_words").val(price_in_words(total));

    if (total >= member_package_purchased_price_value) {
      $(".initial-payment-form5").hide();
      $("#member_balance_payment_amount").val(0);
      $("#member_balance_payment_emi_amount").val(0);
    }
    else {
      $(".initial-payment-form5").show();
      set_emi_date();
      balance_amount = member_package_purchased_price_value - total;
      $("#member_balance_payment_amount").val(balance_amount);
      $("#member_balance_payment_emi_amount").val(balance_amount);
    }
  }

  function set_emi_date() {
    today = new Date();
    numberOfDaysToAdd = 31;
    today.setDate(today.getDate() + numberOfDaysToAdd);

    dd = today.getDate();
    mm = today.getMonth() + 1;
    yy = today.getFullYear();

    emi_date = yy + '/' + mm + '/' + dd;
    $("#member_balance_payment_emi_start_date").val(emi_date);
  }

  function price_in_words(price) {
    var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
      dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
      tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
      handle_tens = function (dgt, prevDgt) {
        return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt])
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];
    if (price += "", isNaN(parseInt(price))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ""));
          break;
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, "Thousand"));
          break;
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, "Lakh"));
          break;
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, "Crore"));
          break;
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "")
      }
      str = words.reverse().join("")
    } else str = "";
    return str
  }
});
