document.addEventListener("turbolinks:load", function () {
  $('[data-behavior=daterangepicker]').daterangepicker({
    locale: { format: 'YYYY/MM/DD' },
    cancelLabel: 'Clear'
  });

  $('[data-behavior=daterangepicker]').on('cancel.daterangepicker', function () {
    $(this).val(' ');
  });
});

document.addEventListener("turbolinks:load", function () {
  $('[data-behavior=datepicker]').daterangepicker({
    locale: { format: 'YYYY/MM/DD' },
    singleDatePicker: true,
    showDropdowns: true,
    minYear: 1901,
    cancelLabel: 'Clear'
  });

  $('[data-behavior=daterangepicker]').on('cancel.daterangepicker', function () {
    $(this).val(' ');
  });
});
