document.addEventListener("turbolinks:load", function () {
  $("input[name='member[member_category]']").on('click', function () {

    registration_date_field = document.getElementById('member_registration_date');
    expiry_date_field = document.getElementById('member_expiry_date');

    function convert_and_set_registration_expiry_for_plans(plan_years) {
      get_full_date = registration_date_field.value.split('/');
      year = get_full_date[0];
      month = get_full_date[1];
      day = get_full_date[2];

      final_year = parseInt(year) + parseInt(plan_years);
      final_month = month;
      final_day = day;

      expiry_date_field.value = [final_year, final_month, final_day].join('/');
    }

    if (this.value === 'silver') {
      convert_and_set_registration_expiry_for_plans(6);
    }
    else if (this.value === 'gold') {
      convert_and_set_registration_expiry_for_plans(10);
    }

    else if (this.value === 'platinum') {
      convert_and_set_registration_expiry_for_plans(20);
    }
  });
});